
export const serverAppConfig = {
  "storeId": "62b43c83052ce4126af4112a",
  "analytics": {
    "gtmId": "GTM-W22M694G",
    "destinations": [
      {
        "type": "GA4",
        "id": "G-B0YQHSTQ27",
        "customMapping": null
      }
    ]
  },
  "privacy": {
    "cookieBotId": null
  },
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "62b43c83052ce417910c112a",
      "name": "Delivery 1",
      "label": "1-3 days",
      "buyable": true
    }
  ],
  "language": "en",
  "currency": "SEK",
  "labels": {
    "assistantChatWith": "Chat with",
    "assistantChatWithFallback": "Customer",
    "assistantShrink": "Shrink",
    "assistantExpand": "Expand",
    "assistantMinimize": "Minimize",
    "assistantReply": "Reply",
    "assistantStatusSent": "Sent",
    "assistantErrorServiceUnavailable": "Service unavailable",
    "assistantErrorUndelivered": "Undelivered",
    "assistantConversationStatusRecording": "Listening...",
    "assistantConversationStatusProcessing": "Processing...",
    "assistantConversationStatusProcessingResponse": "Preparing response...",
    "assistantConversationStatusProcessingResponding": "Responding...",
    "assistantConversationStatusError": "Something went wrong",
    "changePwdFormMsg": "The password must contain at least one number, one lowercase and one uppercase letter, one special character, and be between 8 and 20 characters long.",
    "changePwdFormOldPwd": "Old password",
    "changePwdFormNewPwd": "New password",
    "changePwdFormRepeatPwd": "Repeat password",
    "changePwdFormBtnLabel": "Save",
    "loginForgottenPwd": "Forgotten password?",
    "loginForgottenPwdDesc": "An account is created automatically for you when you place your first order and instructions sent with your order confirmation.",
    "toLogin": "Back to login",
    "requestPwd": "Request new password",
    "accountRegistration": "To be able to follow this and other orders more easily, you can create a password for the account.",
    "contactUsFormName": "Name",
    "contactUsFormPhone": "Phone",
    "contactUsFormEmail": "Email",
    "contactUsFormOrderDate": "Order date",
    "contactUsFormProduct": "Product",
    "contactUsFormOrderNumber": "OrderNumber",
    "contactUsFormDescription": "Description",
    "contactUsFormLocation": "Location",
    "contactUsFormBtnLabel": "Send",
    "contactUsFormAttachFiles": "Attach files",
    "contactConfirmationMessage": "Thank you for submitting your request! We will contact you as soon as possible.",
    "discountFormName": "Do you want to add a discount code?",
    "discountFormBtnLabel": "Add discount",
    "loginPageTitle": "Log in",
    "loginFormEmail": "Email",
    "loginFormPwd": "Password",
    "loginFormBtnLabel": "Login",
    "magicLinkFormEmail": "Email",
    "magicLinkFormBtnLabel": "Email",
    "profileFormFirstName": "First name",
    "profileFormMiddleName": "Middle name",
    "profileFormLastName": "Last name",
    "profileFormOrganizationName": "Organization name",
    "profileFormGender": "Gender",
    "profileFormDateOfBirth": "Birthdate",
    "profileFormBtnLabel": "Send",
    "logout": "Logout",
    "accountUserGreeting": "Welcome",
    "accountHandlerAccountTitle": "Account",
    "accountHandlerOrdersTitle": "Orders",
    "accountHandlerProfileTitle": "Profile",
    "accountHandlerChangePasswordTitle": "Change password",
    "customerOrderOrderNumber": "Order number",
    "customerOrderPaymentDate": "Payment date",
    "customerOrderStatus": "Status",
    "customerOrderShippingCost": "Shipping cost",
    "customerOrderDiscount": "Discount",
    "customerOrderAmount": "Amount",
    "customerOrderDetailInformation": "Information",
    "customerOrderDetailDeliveryAddress": "Delivery address",
    "customerOrderDetailAddress": "Address",
    "customerOrderDetailPostalCode": "Post code",
    "customerOrderDetailAttention": "Attention",
    "customerOrderDetailMobile": "Mobile",
    "customerOrderDetailYourOrder": "Your order",
    "customerOrderDetailPcs": "pcs",
    "noOrdersMessage": "No orders found",
    "noOrdersBtnLabel": "Continue shopping",
    "emptyCart": "No items found",
    "cartServiceShowDetails": "Show details",
    "cartServiceHideDetails": "Hide details",
    "allCategoriesTitle": "All categories",
    "subcategoriesShowMoreBtnLabel": "More",
    "subcategoriesNoCategories": "No categories found",
    "subcategoriesToolbarTitle": "Categories",
    "allCategoriesListDynamic": "Category",
    "checkoutNewsLetter": "Feel free to subscribe to our newsletter to receive updates about our products and special offers: ",
    "newsLetterInputLabel": "Your email",
    "emptyCartBtnLabel": "Start shopping",
    "continueShopping": "Continue shopping",
    "checkoutCartCartItemsMsg": "products in cart",
    "checkoutPageTitle": "Checkout",
    "checkoutServicesHeading": "Services",
    "addToCart": "Add to cart",
    "noServices": "No services",
    "cartTotal": "Cart total",
    "cartToCheckout": "Cart checkout",
    "checkoutSummaryDiscount": "Discount",
    "checkoutSummarySummaryItemProducts": "Products",
    "checkoutSummarySummaryItemServices": "Services",
    "checkoutSummaryCompletePayment": "Complete payment",
    "checkoutSummaryNoProducts": "No products found in the cart",
    "checkoutSummaryDiscountMsg": "Important: If the price of the item is already discounted, it can only have an effect on the original price of the item.",
    "checkoutSummarySummaryTotal": "Total",
    "checkoutSummarySummaryTotalDiscount": "Total discount",
    "checkoutSummarySummaryContinueToPayment": "Continue to payment",
    "confirmationPageRedirectBtnLabel": "My pages",
    "confirmationPageUnknownError": "Unknown error",
    "orderIdNotFound": "Order ID not found",
    "klarnaBackToCart": "Back to cart",
    "productFilesFileLabel": "document",
    "updateCart": "Update",
    "productDetailDeliveryLabelUnavailable": "not available",
    "previousPageBtnLabel": "go back",
    "productRequest": "Product request",
    "productListItemBuyToDetails": "Go to detail",
    "productNotActive": "This product is not active",
    "articleNumber": "Article Number",
    "gtin": "GTIN",
    "brand": "Brands",
    "brandsTitle": "Brands",
    "stockLevel": "Stock level",
    "unit": "units",
    "productTabsDescriptionTitle": "Description",
    "productTabsSpecificationTitle": "Specification",
    "productTabsAttachedFilesTitle": "Attached files",
    "productTabsNoDescription": "No description",
    "productTabsReviewsTitle": "Reviews",
    "notInStock": "Not available in stock",
    "noMedias": "No photos",
    "withMedias": "See photos",
    "accessories": "Accessories",
    "variants": "Variants",
    "variantNotFound": "Variant not found",
    "showMoreBtnLabel": "More",
    "priceTitle": "Price",
    "moreFiltersTitle": "More",
    "reset": "Reset filters",
    "cleanFilters": "Clean",
    "search": "Search",
    "sort": "Sort",
    "showMoreFilters": "Show more filters",
    "filterTitle": "Filter",
    "filterCategoriesTitle": "Category",
    "filterBrandsTitle": "Brand",
    "filterPriceRangeTitle": "Price",
    "filterClear": "Reset filters",
    "filterSubmitText": "Show products",
    "filterRangeSelectorFrom": "From",
    "filterRangeSelectorTo": "to",
    "productListVerticalTitle": "Sort and filter",
    "productListSearchTitle": "Sort and filter",
    "viewTotalProducts": "see products",
    "noProductsFound": "No products found",
    "removeFilter": "Remove filter",
    "uniqueOption": "Unique",
    "notValuesFound": "No values found",
    "sortAndFilter": "Sort and filter",
    "dropzoneDropMsg": "Drop your files here or click to upload",
    "dropzoneAcceptedFiles": "Accepted files",
    "dropzoneReadyMsg": "Ready to upload",
    "dropzoneRejectedMsg": "Rejected files",
    "prisFilterTitle": "Price",
    "searchPlaceholder": "Search products",
    "followUs": "Follow us",
    "searchPanelCloseSearch": "Close search",
    "searchPanelOpenSearch": "Open search",
    "searchPopularResults": "Popular results:",
    "registerAccount": "Register",
    "signupFormEmail": "Email",
    "signupFormFirstName": "First name",
    "signupFormLastName": "Last name",
    "signupFormBtnLabel": "Signup",
    "signUpSuccessMsg": "The account has been registered, you will receive an email shortly with instructions.",
    "signUpErrorMsgRecoverPassword": "Have you lost your password? Recover it ",
    "signupRecoverPasswordLink": "here",
    "signupInvalidCredentials": "Invalid credentials",
    "marketplaceProductsIsAvailable": "Available",
    "marketplaceProductsIsNotAvailable": "Not available",
    "marketplaceProductsShowMore": "Show more",
    "marketplaceProductsShowLess": "Show less",
    "marketplaceProductsNoVendorsFound": "No vendors found",
    "showAll": "Show all",
    "requestSent": "Request sent",
    "quantity": "Quantity",
    "currency": "SEK",
    "priceCurrency": "SEK"
  },
  "paths": {
    "brandsUrlPath": "/brands",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/tags",
    "productsUrlPath": "/products",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "brands",
      "label": "Brands",
      "path": "/brands"
    },
    {
      "id": "blog",
      "label": "Blog",
      "path": "/blog"
    },
    {
      "id": "minasidor",
      "label": "My page",
      "path": "/account"
    }
  ],
  "logoUrl": "",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Price high-low",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Price low-high",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Newest",
      "default": false
    }
  ],
  "desktopItemHeight": "20vw",
  "mobileItemHeight": "45vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 499,
  "shippingProviderConfig": {
    "provider": "DEFAULT",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 499,
      "deliveryFee": 139
    }
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCurrency": "SEK"
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/granditude/?locale=sv_SE"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/granditude/?hl=en"
    }
  ],
  "topNavPanel": {
    "items": [
      {
        "icon": "FaCrown",
        "label": "About us",
        "href": "/about"
      },
      {
        "icon": "MdOutlineLocalShipping",
        "label": "Free shipping"
      },
      {
        "icon": "FaCrown",
        "label": "Our showrooms",
        "href": "/showrooms"
      }
    ]
  }
};
export const serverTheme = {}; 
